@import "~@ui/styles/tools";

$section-part-horizontal-padding: 0 8%;
$section-part-horizontal-padding-mobile: 0 16px;

@mixin medium-breakpoint {
    @media screen and (max-width: 1650px) {
        @content;
    }
  }
  
@mixin small-breakpoint {
    @media screen and (max-width: 1280px) {
        @content;
    }
}

.section {
    padding: 4% 0;
}

.section--stretched-top {
    padding-top: 0;
}

.section--stretched-bottom {
    padding-bottom: 0;
}

.section--stretched-vertically {
    padding-top: 0;
    padding-bottom: 0;
}

.section--filled {
    background: white;
}

.section__header {
    padding: $section-part-horizontal-padding;
    margin-bottom: 32px;

    display: flex;
    justify-content: space-between;
    align-items: center;
}

.section__header-title {
    font-size: 48px;
    font-weight: 600;
    max-width: 60%;
}

.section__body {
    padding: $section-part-horizontal-padding;
    overflow: hidden;
}

.section__body--stretched-left {
    padding-left: 0;
}

.section__body--stretched-right {
    padding-right: 0;
}

.section__body--stretched-horizontally {
    padding-left: 0;
    padding-right: 0;
}

.section__footer {
    padding: $section-part-horizontal-padding;
    margin-top: 4%;
    display: flex;
    justify-content: center;
}

.section__header__action {

}

.section__footer__action {
    display: none;
    padding: $section-part-horizontal-padding;
    margin-top: 20px;
}

@include medium-breakpoint {
    .section__header-title {
        font-size: 40px;
        max-width: 75%;
    }
}

@include small-breakpoint {
    .section__header-title {
        font-size: 32px;
    }
}

@include media-tablet {
    .section__header-title {
        font-size: 24px;
    }

    .section__header {
        padding: $section-part-horizontal-padding-mobile;
    }

    .section__body:not(.section__body--stretched-left, .section__body--stretched-right, .section__body--stretched-horizontally) {
        padding: $section-part-horizontal-padding-mobile;
    }

    .section__footer {
        margin-top: 4%;
        padding: $section-part-horizontal-padding-mobile;
    }
}

@include media-mobile-up {
    .section__header-title--mobile {
        display: none;
    }
}

@include media-mobile {
    .section__header-title--desktop {
        display: none;
    }

    .section__header-title {
        font-size: 26px;
        max-width: 100%;
        width: 100%;
    }

    .section__header-title--long {
        font-size: 22px;
    }

    .section__header__action {
        display: none;
    }

    .section__footer__action {
        display: inherit;
        padding: $section-part-horizontal-padding-mobile;
    }

    .section:not(.section--stretched-top, .section--stretched-bottom, .section--stretched-vertically) {
        padding: 3% 0;
    }

    .section__footer {
        margin-bottom: 10px;
    }
}