@import "~@ui/styles/variables";
@import "~@ui/styles/tools";

@mixin medium-breakpoint {
    @media screen and (max-width: 1650px) {
        @content;
    }
}

@mixin small-breakpoint {
    @media screen and (max-width: 1280px) {
        @content;
    }
}

@mixin xsmall-breakpoint {
    @media screen and (max-width: 1120px) {
        @content;
    }
}

@mixin xxsmall-breakpoint {
    @media screen and (max-width: 950px) {
        @content;
    }
}

$image-aspect-ratio: 1.3265;

.mobile-view {
    display: none;
}

.mobile-header {
    padding: 0 16px;
}

.desktop-view {
    .container {
        display: grid;
        grid-template-areas: 
            'info image'
            'achievements image';
        grid-template-columns: 51% 1fr;
        grid-template-rows: repeat(2, max-content);

        // column-gap: 20px;
    }

    .form {
        grid-area: info;
        align-self: end;
    }

    .achievements {
        grid-area: achievements;
        align-self: start;

        gap: 30px;
    }

    .image-wrapper {
        grid-area: image;
        width: 100%;

        img {
            height: unset !important;
        }
    }
}

.container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    // Used for .mobile-button-wrapper with absolute positioning
    position: relative;
}

.form__heading {
    font-size: 72px;
    font-weight: 500;
    line-height: 1.1;
    margin-bottom: 32px;

    b, em, strong {
        font-weight: 500;
        color: $color-primary;
    }
}

.form__subsection {
    max-width: 85%;
}

.form__description {
    font-size: 20px;
    font-weight: 400;
    line-height: 1.6;
    margin-bottom: 32px;
}

.form__input {
    margin-bottom: 52px;
    display: flex;
    gap: 16px;
}

.image-wrapper {
    // Must use 'relative' positioning with 'fill' prop of next 'Image' component
    position: relative;

    // height: 580px; // relation: 1.5
    height: 650px;
    width: 850px;

    // Override NextJS img styles to keep correct aspect ratio for image
    & > img {
        display: grid;
        // height: unset !important;
        aspect-ratio: $image-aspect-ratio;
    }
}

.achievements {
    display: grid;
    grid-template-columns: max-content max-content;
    grid-template-rows: repeat(2, 1fr);
    gap: 30px;
}

.image-wrapper--mobile {
    // Must use 'relative' positioning with 'fill' prop of next 'Image' component
    position: relative;
    margin-top: 30px;
}

.mobile-button-wrapper {
    display: flex;
    justify-content: center;
}

@media screen and (min-width: 2000px) {
    .image-wrapper {
        height: 550px;
        width: 825px;
    }
}

@media screen and (max-width: 1920px) {
    .form__heading {
        font-size: 57px;
    }
}

@include medium-breakpoint {
    .desktop-view {
        .container {
            column-gap: 10px;
        }

        .achievements {
            row-gap: 15px;
        }

        .image-wrapper {
            margin-left: 0;
            // height: 450px;
            height: 550px;

            img {
                height: unset !important    ;
                // height: 100% !important;
                // width: unset !important;
                left: unset !important;

                top: 50% !important;
                transform: translateY(-50%);
            }
        }
    }

    .form__subsection {
        max-width: 90%;
    }

    .form__heading {
        font-size: 53px;
        margin-bottom: 24px;
    }

    .form__description {
        font-size: 18px;
    }
}

@media screen and (max-width: 1460px) {
    .form__heading {
        font-size: 49px;
    }
}

@include small-breakpoint {
    .desktop-view {
        .image-wrapper {
            height: 460px;
        }
    }

    .container {
        align-items: center;
    }

    .form {
        padding-top: initial;
    }

    .form__heading {
        font-size: 40px;
        margin-bottom: 20px;
    }

    .form__subsection {
        max-width: 90%;
    }

    .form__description {
        font-size: 16px;
        margin-bottom: 20px;
        max-width: 40ch;
    }

    .form__input {
        gap: 6px;
    }

    .image-wrapper {
        margin-left: 0;
    }
}

@media (min-width: 1140px) {
    .form__input {
        &.form__input--small {
                display: none;
        }
    }
}

@media (max-width: 1141px) {
    .form__input {
        &.form__input--large {
                display: none;
        }
    }
}

@include media-tablet {
    .desktop-view {
        .container {
            grid-template-areas: 
                'info image'
                'achievements achievements';
            grid-template-columns: minmax(290px, 1fr) minmax(calc(285px * $image-aspect-ratio), 1fr);
        }

        .form {
            align-self: start;
        }

        .achievements {
            margin-top: 32px;

            grid-template-columns: repeat(4, max-content);
            grid-template-rows: max-content;

            justify-content: space-around;
        }

        .image-wrapper {
            height: 285px;
        }
    }

    .form__input {
        flex-direction: column;
        margin-bottom: 0;

        &.form__input--small {
                display: none;
        }

        &.form__input--large {
                display: inherit;
        }
    }

    .image-wrapper {
        margin-bottom: 0;
    }
}

@include xxsmall-breakpoint {
    .desktop-view {
        .achievements {
            gap: 10px;
        }
    }

    .form__heading {
        font-size: 28px;
        margin-bottom: 16px;
    }

    .form__description {
        font-size: 14px;
        margin-bottom: 16px;
    }

    .image-wrapper {
        height: 300px;
        width: 450px;
    }

}

@include media-tablet-portrait {
    .desktop-view {
        .container {
            grid-template-columns: minmax(290px, 1fr) 1fr;
            column-gap: 10px;
        }

        .achievements {
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: repeat(2, max-content);
        }

        .image-wrapper {
            // width: 100%;
            // height: 100%;
            // height: 245px;


            img {
                // height: auto !important;
                height: auto !important;
                width: 100% !important;
                left: 0 !important;
            }
        }
    }
}

@media screen and (max-width: 650px) {
    .desktop-view {
        .container {
            grid-template-columns: minmax(235px, 1fr) 1fr;
            row-gap: 10px;
        }
    }
}


@include media-mobile {
    .desktop-view {
        display: none;
    }
    
    .mobile-view {
        display: block;
    }

    .container {
        display: block;
        gap: 0;
    }

    .form__heading {
        font-size: 36px;
        margin-bottom: 10px;
        width: 95%;
    }

    .image-wrapper--mobile {
        aspect-ratio: 1.385;
    }

    .achievements {
        grid-template-columns: repeat(2, max-content);
        margin-bottom: 30px;
        gap: 7px 20px;
    }
}

@media screen and (max-width: 650px) {
    .desktop-view {
        .image-wrapper {
            height: 224px;
        }
    }
}

@media screen and (max-width: 350px) {
    .mobile-view {
        .achievements {
            grid-template-columns: 1fr;
            grid-template-rows: repeat(4, max-content);
        }
    }
}